import React from 'react';
import {useState} from "react";
import {
    Typography, useScrollTrigger, Button, DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    SwipeableDrawer, ListItem, List, ListItemText, useMediaQuery, IconButton, Container
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {AppBar, Toolbar} from "@mui/material";
import {Link} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from '../../assets/images/logo.svg';

import { createTheme } from "@mui/material/styles";

const theme = createTheme({});

function ElevationScroll(props) {
    const {children} = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const Topbar = () => {

    const appBarStyle = {
        backgroundColor: theme.palette.background.default
    };

    const logoStyle = {marginTop: "0.8em"};


    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const [openDrawer, setOpenDrawer] = useState(false);

    const drawerLogoStyle = {
        height: "3em",
        [theme.breakpoints.down("md")]: {height: "3.4em"},
        [theme.breakpoints.down("xs")]: {height: "3.0em"}
    }

    const drawerItemStyle = {
        textTransform: "none",
        fontWeight: 700,
        fontSize: "1rem",
    }

    const drawerIconContainerStyle = {
        marginLeft: "auto",
        hover: {
            backgroundColor: "transparent"
        }
    }

    const drawer = (
        <>
            <Link to="/" style={logoStyle}>
                <img src={logo} width="300px" alt="Abren Le Abrehot Logo" />
            </Link>
            <p className='w-full text-lg font-bold text-abrehotBlue ml-2'>አብረን ለ አብርሆት <br /> <span className='text-abrehotYellow'>Together for Enlightenment</span> </p>
            <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS} open={openDrawer} anchor="right"
                             onClose={() => setOpenDrawer(false)} onOpen={() => setOpenDrawer(true)}>
                <List disablePadding>
                    <ListItem onClick={() => setOpenDrawer(false)} divider component={Link} to="/">
                        <img src={logo} style={drawerLogoStyle}/>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)} divider button component={Link} to="/">
                        <ListItemText style={drawerItemStyle} disableTypography>Home</ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)} divider button component={Link} to="/team">
                        <ListItemText style={drawerItemStyle} disableTypography>Team</ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)} divider button component={Link} to="/background">
                        <ListItemText style={drawerItemStyle} disableTypography>Background</ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)} divider button component={Link} to="/activities">
                        <ListItemText style={drawerItemStyle} disableTypography>Activities</ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)} divider button component={Link} to="/contact">
                        <ListItemText style={drawerItemStyle} disableTypography>Contact</ListItemText>
                    </ListItem>
                </List>
            </SwipeableDrawer>
            <IconButton style={drawerIconContainerStyle} onClick={() => setOpenDrawer(!openDrawer)}
                        disableRipple>
                <MenuIcon color="textSecondary" fontSize="large"/>
            </IconButton>
        </>
    )


    return (
        <>
            <ElevationScroll>
                <AppBar position="relative" style={appBarStyle}>
                    <Container maxWidth="lg">
                        <Toolbar disableGutters>
                            {drawer}
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
        </>
    );
};

export default Topbar;