import { React, useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.svg';
import { useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({});


function Navbar() {
    const [nav, setnav] = useState(false)
    const handleNav = () => {
        setnav(!nav)
    }

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className='w-full bg-primaryThemeColor static'>
            <div className=' flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 border-b border-b-gray-300 text-secondaryThemeColor'>
                <Link onClick={handleNav} to="/">
                    <img src={logo} width="400px" alt="Abren Le Abrehot Logo" />
                </Link>
                {matches ? <p className='w-full text-md font-bold text-abrehotBlue ml-2'>አብረን ለ አብርሆት <br /> <span className='text-abrehotYellow'>Together for Enlightenment</span> </p> :
                    <p className='w-full text-1xl font-bold text-abrehotBlue ml-2'>አብረን ለ አብርሆት <br /> <span className='text-abrehotYellow'>Together for Enlightenment</span></p>
                }
                <ul className='hidden md:flex text-1xl  font-medium'>
                    <Link to="/" className='p-4  hover:bg-white hover:text-gray-400 hover:underline rounded-md '><li>HOME</li></Link>
                    <Link to="/team" className='p-4 hover:bg-white hover:text-gray-400 hover:underline rounded-md '><li>TEAM</li></Link>
                    <Link to="/background" className='p-4 hover:bg-white hover:text-gray-400 hover:underline rounded-md '><li>BACKGROUND</li></Link>
                    <Link to="/activities" className='p-4 hover:bg-white hover:text-gray-400 hover:underline rounded-md '><li>ACTIVITIES</li></Link>
                    <Link to="/contact" className='p-4 hover:bg-white hover:text-gray-400 hover:underline rounded-md '><li>CONTACT</li></Link>
                </ul>
                <div onClick={handleNav} className='block md:hidden'>
                    {
                        nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />
                    }
                </div>
                <div className={nav ? 'md:hidden top-0 w-[60%] h-full border-r border-r-gray-900 bg-primaryThemeColor ease-in-out duration-500' : 'fixed left-[-100%]'}>
                    <h1 className='w-full text-3xl font-extralight p-4 ml-2 text-secondaryThemeColor'>Abren le Abrehot</h1>
                    <ul className='uppercase p-4'>
                        <Link onClick={handleNav} to="/" ><li className='p-3 border-b border-gray-600'>HOME</li></Link>
                        <Link onClick={handleNav} to="/team" ><li className='p-3 border-b border-gray-600'>TEAM</li></Link>
                        <Link onClick={handleNav} to="/background" ><li className='p-3 border-b border-gray-600'>BACKGROUND</li></Link>
                        <Link onClick={handleNav} to="/activities" ><li className='p-3 border-b border-gray-600'>ACTIVITIES</li></Link>
                        <Link onClick={handleNav} to="/contact" ><li className='p-3 border-b border-gray-600'>CONTACT</li></Link>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar