import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Button, Card, CardContent, Grid, Typography, useMediaQuery } from "@mui/material";
import * as messages from '../../../constants/constants';
import {
    FaQuoteLeft,
    FaQuoteRight
} from 'react-icons/fa'
import { createTheme } from "@mui/material/styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const theme = createTheme({});

const EMBASSY_OF_ETHIOPIA_TITLE = "Embassy of Ethiopia, London (UK)";
const EMBASSY_OF_ETHIOPIA_MSG = "Inspired by Abren Le Abrehot, the Embassy of Ethiopia in London, UK began its own book drive for STEM and children’s books across the UK. Donations from libraries, universities and other institutions have allowed the Embassy to collect over 8,000 books which will be donated to Abrehot in April 2023."

const REMLA_TITLE = "Remla (USA)";
const REMLA_MSG = "After seeing the work by this initiative, we were moved and decided to donate books on behalf of Keru Shafi. Keru was a husband, a father, and a lover of reading. He was a great advocate for people to read more and enjoyed sharing knowledge. We are very grateful that the initiative will keep Keru’s love for reading and knowledge alive."

const NOAH_TITLE = "Noah Daniel, Abby Yosef and Alessandro Berardinl (USA)";
const NOAH_MSG = "This project was really inspirational and taught us that we can use the available resources around us to make a big impact. Two friends and I (all based in the DMV area) collected books from our families, friends, local churches, and people we knew. In August 2022, we were able to donate around 250 books in person. Seeing the beautiful architecture of Abrehot and educational opportunity it will provide for the generation instilled a lot of hope in us."

const AMEN_TITLE = "Amen Gashaw (USA)";
const AMEN_MSG = "For several years, my mother and I have led donation drives to support Ethiopian and other families new to the US. We saw Abrehot’s grand opening and then learned about this initiative and reached out to Bethlehem. My mom and I were able to quickly organize a book-transfer, and my mom soon donated 200 children’s books. Thanks to Abren Le Abrehot, we were able to take our local project across the ocean."

const SIELE_TITLE = "Siele Shifferaw (Ethiopia) + team";
const SIELE_MSG = "My team and I are part of a new local NGOs based in Ethiopia trying to establish a community Library and Information center in Addis Ababa. Inspired by Bethlehem’s work, we are partnering with this initiative to get STEM and children’s books for our NGO and other libraries/centers across Ethiopia."

const ASSEFA_TITLE = "Assefa Gebremariam Family (Ethiopia)";
const ASSEFA_MSG = "After seeing the grand opening of Abrehot library, we were inspired to give by donating our father's old books. The sight of so many eager readers and the need for books in the library was a motivating factor for us. We wanted to contribute to the cause of promoting literacy and education in Ethiopia. The joy of knowing that the books would be read and appreciated by others made the decision to donate an easy one."

const Testimonials = () => {
    const [index, setIndex] = useState(0);

    const handleChangeIndex = (index) => {
        setIndex(index);
    };

    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const gridOrientation = matches ? "column" : "row";

    const textColumnWidth = matches ? 10 : 5;

    const containerStyle = {
        paddingBottom: "2em",
    }

    const testimonyCard = (title1, blurb1, title2, blurb2) => {

        const cardStyle = {
            // padding: "0.7em",
            // margin: "0.7em",
        }

        const cardHeight = {
            height: "400px",
            margin: "0.5px",
        }
        return (
            <Grid container item justifyContent="space-around" spacing={3} direction={gridOrientation}>
                <Grid item style={cardStyle} xs={textColumnWidth}>
                    {/*<Card raised>*/}
                    {/*    <CardContent style={cardHeight}>*/}
                            <FaQuoteLeft size={30} color="#f1c232" />
                            <br />
                            <Typography>{blurb1}</Typography>
                            <br />
                            <FaQuoteRight size={30} color="#f1c232" />
                            <br />
                            <Typography fontWeight={600}>{title1}</Typography>
                    {/*    </CardContent>*/}
                    {/*</Card>*/}
                </Grid>
                <Grid item style={cardStyle} xs={textColumnWidth}>
                    {/*<Card raised>*/}
                    {/*    <CardContent style={cardHeight}>*/}
                            <FaQuoteLeft size={30} color="#f1c232" />
                            <br />
                            <Typography>{blurb2}</Typography>
                            <br />
                            <FaQuoteRight size={30} color="#f1c232" />
                            <br />
                            <Typography fontWeight={600}>{title2}</Typography>
                    {/*    </CardContent>*/}
                    {/*</Card>*/}
                </Grid>
            </Grid>
        )
    }

    const buttonStyle = {
        borderRadius: "5px",
        marginRight: "25px",
        textTransform: "none",
        background: "#f1c232"
    };

    return (
        <Grid container direction="column" alignItems="center" spacing={2} style={containerStyle}>
            <Grid item>
                <h1 className='w-full text-4xl font-semibold justify-center text-center text-abrehotYellow'>{messages.TESTIMONIALS_HEADER}</h1>
            </Grid>
            <br />
            <Grid item>
                <h1 className='w-full text-2xl font-medium justify-center text-center text-gray-600'>Over 100 benefactors. From over 20 countries.</h1>
            </Grid>
            <br />
            <Grid container item justifyContent="space-around">
                <AutoPlaySwipeableViews
                    index={index}
                    onChangeIndex={(index) => handleChangeIndex(index)}
                    enableMouseEvents
                    interval={10000}  // 10 seconds.
                >
                    {testimonyCard(EMBASSY_OF_ETHIOPIA_TITLE, EMBASSY_OF_ETHIOPIA_MSG, REMLA_TITLE, REMLA_MSG)}
                    {testimonyCard(NOAH_TITLE, NOAH_MSG, AMEN_TITLE, AMEN_MSG)}
                    {testimonyCard(SIELE_TITLE, SIELE_MSG, ASSEFA_TITLE, ASSEFA_MSG)}
                </AutoPlaySwipeableViews>
            </Grid>
            <Grid item>
                <Button variant="contained" style={buttonStyle} size="large" fullWidth href="/contact"><Typography fontWeight={500}>JOIN
                    THE EFFORT</Typography></Button>
            </Grid>
        </Grid>
    );
}

export default Testimonials;