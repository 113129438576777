import * as messages from "../../constants/constants";
import React from 'react';

function Activities() {
    return (
        <div className='w-full text-black bg-white bg-opacity-100' >
            <div className='max-w-[900px] min-h-screen mx-auto text-center justify-center flex-col'>
                <div className='flex flex-col w-full mx-auto  p-6'>
                    <h1 className='w-full text-center md:text-3xl sm:text-2xl font-bold mb-6'>CURRENT WORK</h1>
                    <p className='py-2 text-justify'>{messages.STORY_CURRENTwORK_1}</p>
                    <p className='py-2 text-justify'>{messages.STORY_CURRENTwORK_2}</p>
                    <p className='py-2 text-justify'>{messages.STORY_CURRENTwORK_3}</p>
                    <p className='py-2 text-justify'>{messages.STORY_CURRENTwORK_4}</p>
                    <p className='py-2 text-justify'>{messages.STORY_CURRENTwORK_5}</p>
                </div>
            </div>
        </div >
    )
}

export default Activities