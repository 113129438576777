import { Button, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import * as messages from '../../constants/constants';
import React from "react";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({});

const Profile = (name, bio, img) => {

    const nameArray = name.split(" ");
    const containerStyle = {
        backgroundColor: grey[50],
    }

    return (
        <Card sx={{ maxWidth: 430, minHeight: 425 }}>
            <Grid container spacing={1} alignItems="center" justifyContent="left" style={containerStyle}>
                <Grid item xs={6}>
                    <CardMedia
                        sx={{ height: 160, width: 160 }}
                        image={require('../../assets/images/TeamPicturesGrayscale/' + img)}
                        title="Team member profile"
                    />
                </Grid>
                <Grid container item direction="column" xs={6}>
                    <Grid item>
                        <Typography variant="" fontWeight={700} fontSize="1.1rem">{nameArray[0]}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="" fontWeight={700} fontSize="1.1rem">{nameArray[1]}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                    {bio}
                </Typography>
            </CardContent>
        </Card>
    )
}

const Team = () => {

    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const gridOrientation = matches ? "column" : "row";
    const profileWidth = matches ? 10 : 4;
    const textWidth = matches ? 12 : 8;

    const buttonStyle = {
        borderRadius: "5px",
        marginRight: "25px",
        textTransform: "none",
        background: "#f1c232"
    };

    return (
        <Grid container direction="column" spacing={4}>
            <br />
            <Grid item alignSelf="center">
                <Typography variant="h4" fontWeight={700}>Meet the team</Typography>
            </Grid>
            <Grid container item alignSelf="center" direction={gridOrientation} spacing={3} justifyContent="space-around">
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_BETHELHEM.name, messages.BIO_BETHELHEM.bio, 'Betty.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_DINA.name, messages.BIO_DINA.bio, 'Dina.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_WES.name, messages.BIO_WES.bio, 'Wes.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_ESAYAS.name, messages.BIO_ESAYAS.bio, 'Esayas.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_SAMRAWIT.name, messages.BIO_SAMRAWIT.bio, 'Samrawit.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_BEMNET.name, messages.BIO_BEMNET.bio, 'Bemnet.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_KIRUBEL.name, messages.BIO_KIRUBEL.bio, 'Kirubel.jpg')}
                </Grid>
                <Grid item xs={profileWidth}>
                    {Profile(messages.BIO_MICHAEL.name, messages.BIO_MICHAEL.bio, 'Michael.jpg')}
                </Grid>
            </Grid>
            <Grid container item justifyContent="center">
                <Grid item xs={textWidth}>
                    <Typography variant="h4" fontWeight={700}>We're committed to Abren Le Abrehot's mission & appreciate your support.</Typography>
                </Grid>
            </Grid>
            <Grid item alignSelf="center">
                <Button variant="contained" style={buttonStyle} size="large" href="/contact"><Typography fontWeight={500}>SUPPORT US</Typography></Button>
            </Grid>
        </Grid>
    )
}

export default Team;