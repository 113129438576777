import {BrowserRouter} from "react-router-dom";
import AppRoutes from './AppRoutes';
import Navbar from './components/ui/Navbar';
import Footer from "./components/Footer";
import {Container, CssBaseline, useMediaQuery} from "@mui/material";
import Topbar from "./components/ui/Topbar";
import {createTheme} from "@mui/material/styles";

const theme = createTheme({});

function App() {
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className="App">
            <CssBaseline/>
            <BrowserRouter>
                <Container maxWidth="lg">
                    {matches ? <Topbar/> : <Navbar/>}
                    <AppRoutes/>
                    <Footer/>
                </Container>
            </BrowserRouter>
        </div>
);
}

export default App;
