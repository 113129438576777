import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Pagination from './Pagination';
import { Grid, Typography, useMediaQuery } from "@mui/material";
import * as messages from '../../../constants/constants';
import AbrehotExterior from '../../../assets/images/Abrehot_Exterior_01_resized.jpg';
import EthiopianPopulation from '../../../assets/images/EthPop.png';
import YouthAtAbrehot from '../../../assets/images/YouthAtAbrehot.jpg';
import AbrehotShelves from '../../../assets/images/AbrehotShelves.jpg';
import BethDonate from '../../../assets/images/BethDonate.jpg';
import DonorTestimony from '../../../assets/images/BenefactorNoah.png';
import { createTheme } from "@mui/material/styles";
import { ABREHOT_INITIATIVE } from "../../../constants/constants";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const theme = createTheme({});

const Slideshow = () => {
    const [index, setIndex] = useState(0);

    const handleChangeIndex = (index) => {
        setIndex(index);
    };

    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const gridOrientation = matches ? "column" : "row";

    const textColumnWidth = 10;

    const slide = (imgSrc, blurb) => {
        return (
            <Grid container item direction="column" alignItems="center" spacing={2} xs={12}>
                <Grid item xs={12}>
                    <img src={imgSrc} />
                </Grid>
                <Grid item xs={textColumnWidth}>
                    <h1 className='w-full text-md font-semibold text-gray-600'>{blurb}</h1>
                </Grid>
            </Grid>
        )
    }


    return (
        <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={(index) => handleChangeIndex(index)}
            enableMouseEvents
            interval={8000}  // 8 seconds.
        >
            {slide(AbrehotExterior, messages.ABREHOT_DESCRIPTION_0NE)}
            {slide(EthiopianPopulation, messages.ABREHOT_TARGET)}
            {slide(YouthAtAbrehot, messages.ABREHOT_NEED)}
            {slide(AbrehotShelves, messages.ABREHOT_INITIATIVE)}
            {slide(BethDonate, messages.ABREHOT_CAMPAIGN)}
            {slide(DonorTestimony, messages.ABREHOT_BENEFACTOR)}
        </AutoPlaySwipeableViews>
        /*<Pagination dots={3} index={index} onChangeIndex={(index) => handleChangeIndex(index)}/>*/
    )
}

export default Slideshow;