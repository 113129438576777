import React, {useState} from 'react'
import {Box, Button, Grid, TextField, Typography, useMediaQuery} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import logo from '../../assets/images/logo.jpg';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const theme = createTheme({
});

function Contact() {

    const pageStyle = {
        backgroundColor: "#FFFFFF",
        marginTop: "40px",
        paddingBottom: "40px",
        paddingLeft: "10px",
        paddingRight: "10px",
    }

    const meansOfContactStyle = {
        marginTop: "30px",
    }

    const [textInput, setTextInput] = useState(() => {
        return {
            name: "",
            email: "",
            subject: "",
            message: "",
            submitted: false,
        }
    })

    const handleNameChange = (event) => {
        setTextInput(prevState => {
            return {
                ...prevState,
                name: event.target.value,
            }
        })
    }

    const handleEmailChange = (event) => {
        setTextInput(prevState => {
            return {
                ...prevState,
                email: event.target.value,
            }
        })
    }

    const handleSubjectChange = (event) => {
        setTextInput(prevState => {
            return {
                ...prevState,
                subject: event.target.value,
            }
        })
    }

    const handleMessageChange = (event) => {
        setTextInput(prevState => {
            return {
                ...prevState,
                message: event.target.value,
            }
        })
    }

    const handleSubmit = () => {

        const templateParams = {
            name: textInput.name,
            email: textInput.email,
            subject: textInput.subject,
            message: textInput.message
        };

        // Uses emailjs (free service) to send email with the template params. Currently using my own email for testing.
        // TODO: Create a new email for this project and use that instead.
        // emailjs.send("service_3d7ir4k", "template_b9kucgq", templateParams, "DJeDniig9Q8qao638").then(() => {
        //     console.log("Message sent.");
        // }, (error) => {
        //     console.log(error.text);
        // });

        setTextInput(prevState => {
            return {
                ...prevState,
                submitted: true,
            }
        })

    }

    const requiredInputsProvided = () => {
        return textInput.email.length !== 0 && textInput.message.length !== 0;
    }

    const donationImpactInfo = () => {
        return (
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Typography fontWeight={600} variant="h6">Your donation impacts lives!</Typography>
                </Grid>
                <Grid item>
                    <Typography color="textPrimary" align="left">
                        <CheckCircleIcon fontSize="small"/>
                        &nbsp;Stat showing impact of a donation.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="textPrimary" align="left">
                        <CheckCircleIcon fontSize="small"/>
                        &nbsp;Stat 2.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="textPrimary" align="left">
                        <CheckCircleIcon fontSize="small"/>
                        &nbsp;Stat 3.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="textPrimary" align="left">
                        <CheckCircleIcon fontSize="small"/>
                        &nbsp;Stat 4.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const contactForm = () => {

        const formStyle = {
            padding: "50px"
        }

        const submitButton = (
            <Grid item>
                <Button variant="contained" fullWidth disabled={!requiredInputsProvided()}
                        onClick={handleSubmit}>Submit</Button>
            </Grid>
        )

        const submittedMessage = () => {
            return (

                <Grid item>
                    <Button variant="text" fullWidth disabled colo="success"><Typography>Message submitted!</Typography></Button>
                </Grid>
            )
        }

            return (
                <Box sx={{boxShadow: 2}} style={formStyle}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography>Name</Typography>
                            <TextField label="Your name..." fullWidth onChange={handleNameChange}/>
                        </Grid>
                        <Grid item>
                            <Typography>Email (required)</Typography>
                            <TextField label="Your email..." required fullWidth onChange={handleEmailChange}/>
                        </Grid>
                        <Grid item>
                            <Typography>Subject</Typography>
                            <TextField label="Subject..." fullWidth onChange={handleSubjectChange}/>
                        </Grid>
                        <Grid item>
                            <Typography>Message (required)</Typography>
                            <TextField label="Your message" required fullWidth multiline rows={6}
                                       onChange={handleMessageChange}/>
                        </Grid>
                        {textInput.submitted ? submittedMessage() : submitButton}
                    </Grid>
                </Box>
            )
        }


    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const gridOrientation = matches ? "column-reverse" : "row";
    const hideImage = matches;

    const textColumnWidth = matches ? 10 : 8;

    return (
        <Grid container direction="column" style={pageStyle} alignItems="center" spacing={3}>
            <Grid item>
                <Typography variant="h2">Get involved!</Typography>
            </Grid>
            <Grid item>
                <Typography color="textSecondary" variant="h6">Support our mission by donating to our initiative.</Typography>
            </Grid>
            <Grid item>
                <Typography color="textSecondary" variant="h6" align="center">Credit card donations are coming soon.</Typography>
                <Typography color="textSecondary" variant="h6" align="center">For now, please email us at <b>info@leabrehot.org</b> to make a contribution.</Typography>
                <Typography color="textSecondary" variant="h6" align="center">We are grateful for your support.</Typography>
            </Grid>
            <Grid item>
                <Typography color="textSecondary" variant="h6" fontWeight={700}>We'd love to hear from you!</Typography>
            </Grid>
                <Grid container item style={meansOfContactStyle} direction={gridOrientation} spacing={2} justifyContent="space-around">
                    {/*<Grid item xs={textColumnWidth}>*/}
                    {/*    {donationImpactInfo()}*/}
                    {/*</Grid>*/}
                    <Grid item xs={textColumnWidth}>
                        {contactForm()}
                    </Grid>
                </Grid>
        </Grid>
    )
}

export default Contact