import React from 'react'
import { BookOpenIcon, ArrowsExpandIcon, BeakerIcon, FireIcon } from '@heroicons/react/solid'
import * as messages from '../../constants/constants';

function Cards() {

    const card = (title, description, icon) => {
        return (
            <>
                <div className='w-full bg-gradient-to-r from-yellowGrad to-blueGrad text-black shadow-xl flex flex-col p-8 my-8 rounded-lg hover:scale-105 duration-300'>
                    <div className='w-20 h-20 mx-auto text-abrehotYellow my-2'>
                        {icon}
                    </div>
                    <h2 className='text-2xl font-bold text-center py-8'>{title}</h2>
                    <div className='text-center font-medium'>
                        <p>{description}</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='w-full bg-white mx-auto'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-8 pb-1'>
                {card(messages.CARD_ONE_TITLE, messages.CARD_ONE_DESCRIPTION, <BookOpenIcon/>)}
                {card(messages.CARD_TWO_TITLE, messages.CARD_TWO_DESCRIPTION, <ArrowsExpandIcon/>)}
                {card(messages.CARD_THREE_TITLE, messages.CARD_THREE_DESCRIPTION, <BeakerIcon/>)}
                {card(messages.CARD_FOUR_TITLE, messages.CARD_FOUR_DESCRIPTION, <FireIcon/>)}
            </div>
        </div>
    )
}

export default Cards