import React from 'react'
import {
    FaInstagram,
    FaTwitterSquare,
    FaFacebookSquare,
} from 'react-icons/fa'
import { IconButton } from "@mui/material";

function Footer() {
    return (
        <div className='bg-primaryThemeColor border-t border-t-gray-300 mt-4'>
            <div className='max-w-[800px]  w-full py-6 px-4 mx-auto text-center text-secondaryThemeColor' >
                <div>
                    <div className='flex w-[50%] md:w-[25%] justify-between mx-auto my-3'>
                        <IconButton onClick={() => window.open('https://www.facebook.com/profile.php?id=100090432577493', '_blank')} color="inherit">
                            <FaFacebookSquare size={30} />
                        </IconButton>
                        <IconButton onClick={() => window.open('https://www.instagram.com/abrenleabrehot', '_blank')} color="inherit">
                            <FaInstagram size={30} />
                        </IconButton>
                        <IconButton onClick={() => window.open('https://twitter.com/AbrenLeAbrehot?t=2QGB9pP2K20vyiFfeFcsxQ&s=08', '_blank')} color="inherit">
                            <FaTwitterSquare size={30} />
                        </IconButton>
                    </div>
                    <p className='py-1'>© 2023 Abren Le Abrehot - Together for Enlightenment</p>
                </div>
            </div>
        </div>
    )
}

export default Footer