import React from 'react'
import * as messages from '../../constants/constants';
import {createTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

const theme = createTheme({});

function Background() {

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    if (matches) {

        return (
            <div className='w-full text-black bg-white bg-opacity-100'>
                <div className='max-w-[900px] min-h-screen mx-auto text-center justify-center flex-col'>
                    <div className='flex flex-col w-full mx-auto  p-6'>
                        <h1 className='w-full text-center md:text-3xl sm:text-2xl font-bold mb-6'>BACKGROUND</h1>
                        <p className='py-2 text-justify'>{messages.STORY_BACKGROUND_1}</p>
                        <p className='py-2 text-justify'>{messages.STORY_BACKGROUND_2}</p>
                        <p className='py-2 text-justify'>{messages.STORY_BACKGROUND_3}</p>
                    </div>
                    <div className='flex flex-col text-start md:w-[65%] p-6'>
                        <h1 className='font-bold text-xl'>References to our first book collection initiative</h1>
                        <a href="https://abrehot.org.et/fundraiser/"
                           target="_blank" rel="noreferrer">
                            <div
                                className="w-full bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300">
                                <h5 className="mb-2 text-lg font-light tracking-tight ">Abrehot Library - Donors gave
                                    more than 5,000 Books.</h5>
                            </div>
                        </a>
                        <a href="https://www.youtube.com/watch?v=FOa7G85yaDQ"
                           target="_blank" rel="noreferrer">
                            <div
                                className="w-full bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300">
                                <h5 className="mb-2 text-lg font-light tracking-tight ">መጻሕፍትን ለአብርሆት ቤተመጻሕፍት ያበረከተችው
                                    ወጣት</h5>
                            </div>
                        </a>
                        <a href="https://www.imperial.ac.uk/news/236257/donation-driveraises-more-than-5000-books/"
                           target="_blank" rel="noreferrer">
                            <div
                                className="w-full bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300">
                                <h5 className="mb-2 text-lg font-light tracking-tight ">Donation drive raises more than
                                    5,000 books for Ethiopia’s largest library.</h5>
                            </div>
                        </a>
                        <a href="https://www.gofundme.com/f/AbrenLeAbrehot"
                           target="_blank" rel="noreferrer">
                            <div
                                className="w-full bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300">
                                <h5 className="mb-2 text-lg font-light tracking-tight ">GoFundMe campaign raised over
                                    6000 dollars.</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='w-full text-black bg-white bg-opacity-100' >
            <div className='max-w-[900px] min-h-screen mx-auto text-center justify-center flex-col'>
                <div className='flex flex-col w-full mx-auto  p-6'>
                    <h1 className='w-full text-center md:text-3xl sm:text-2xl font-bold mb-6'>BACKGROUND</h1>
                    <p className='py-2 text-justify'>{messages.STORY_BACKGROUND_1}</p>
                    <p className='py-2 text-justify'>{messages.STORY_BACKGROUND_2}</p>
                    <p className='py-2 text-justify'>{messages.STORY_BACKGROUND_3}</p>
                </div>
                <div>
                    <h1 className='font-bold text-xl'>References to our first book collection initiative</h1>
                    <div className='flex justify-center md:w-full p-6'>
                        <div className='w-full ml-5 mr-5'>
                            <a href="https://abrehot.org.et/fundraiser/"
                                target="_blank" rel="noreferrer">
                                <div
                                    className=" bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300 h-[100px]">
                                    <h5 className="mb-2 text-lg font-light tracking-tight ">Abrehot Library - Donors gave more than 5,000 Books.</h5>
                                </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=FOa7G85yaDQ"
                                target="_blank" rel="noreferrer">
                                <div
                                    className=" bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300 h-[100px]">
                                    <h5 className="mb-2 text-lg font-light tracking-tight">መጻሕፍትን ለአብርሆት ቤተመጻሕፍት ያበረከተችው ወጣት</h5>
                                </div>
                            </a>
                        </div>
                        <div className='w-full ml-5 mr-5'>
                            <a href="https://www.imperial.ac.uk/news/236257/donation-driveraises-more-than-5000-books/" target="_blank" rel="noreferrer">
                                <div className=" bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300 h-[100px]">
                                    <h5 className="mb-2 text-lg font-light tracking-tight ">Donation drive raises  more than 5,000 books for Ethiopia’s largest library.</h5>
                                </div>
                            </a>
                            <a href="https://www.gofundme.com/f/AbrenLeAbrehot"
                                target="_blank" rel="noreferrer">
                                <div
                                    className=" bg-abrehotYellow text-abrehotBlue shadow-xl flex flex-col p-4 my-3 rounded-lg hover:scale-105 duration-300 h-[100px]">
                                    <h5 className="mb-2 text-lg font-light tracking-tight ">GoFundMe campaign raised over 8000 dollars.</h5>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default Background