import { Route, Routes } from 'react-router-dom';
import Team from "./pages/Team/Team";
import Landing from './pages/Landing/Landing';
import Contact from './pages/Contact/Contact';
import Activities from "./pages/Activities/Activities";
import Background from "./pages/Background/Background";


function AppRoutes() {
    return (
        <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route path="/team" element={<Team />} />
            <Route path="/background" element={<Background />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/activities" element={<Activities/>} />
        </Routes>
    )
}

export default AppRoutes