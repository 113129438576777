//#region HomePage
export const TOGETHER_FOR_ABREHOT = "Together for Abrehot";
export const ABREN_LE_ABREHOT = "አብረን ለ አብርሆት";
export const SLOGAN_MAIN_TITLE = "Our vision";
export const SLOGAN_MAIN = "To provide access to knowledge that inspires Ethiopian youth to build an innovative, tolerant, and prosperous society.";
export const ABREHOT_DESCRIPTION_0NE = "Abrehot Library is a public library in Addis Ababa, Ethiopia. At a size of 19,000 square meters, it is the largest library in the country.";
export const ABREHOT_DESCRIPTION_TWO = "Abren Le Abrehot is about creating an avenue for exploration and understanding, providing exposure to new people, places and ideas equipping people with a passionate pursuit for even greater dreams, and developing vital skills like writing and critical thinking.";
export const ABREHOT_TARGET = "Ethiopia has a population of 120 million that is growing rapidly, with 60 percent of the population under 25 years of age."
export const ABREHOT_INITIATIVE = "Together for Englightenment is an initiative to improve access to books for Ethiopian youth, at Abrehot library and beyond."
export const ABREHOT_NEED = "Access to books is especially critical for this segment of the population, which mostly consists of people in school."
export const ABREHOT_CAMPAIGN = "The initiative has collected and donated 5000+ books since it's founding in January 2022."
export const ABREHOT_BENEFACTOR = "The initiative has also inspired several people, like the group shown here, to donate books."

export const INITIATIVE_TITLE = "An initiative to improve Ethiopian youth access to books.";
export const INITIATIVE_ONE_TITLE = "Serving a growing population.";
export const INITIATIVE_ONE_DESCRIPTION = "60 percent of Ethiopia's 120 million and growing population is under 25 years old.";
export const INITIATIVE_TWO_TITLE = "Meeting a critical need.";
export const INITIATIVE_TWO_DESCRIPTION = "Access to books is especially critical for this population which mostly consists of people still in school.";
export const INITIATIVE_THREE_TITLE = "5,000+ books donated.";
export const INITIATIVE_THREE_DESCRIPTION = "Founded in January 2022, Abren-Le-Abrehot has inspired thousands of people to donate.";
export const INITIATIVE_FOUR_TITLE = "A global movement.";
export const INITIATIVE_FOUR_DESCRIPTION = "Abren Le Abrehot is a global movement that is open to anyone who wants to help."

export const CARD_ONE_TITLE = "Equip libraries";
export const CARD_ONE_DESCRIPTION = "By providing libraries with STEM-oriented books targeting senior high-school and undergraduate students.";
export const CARD_TWO_TITLE = "Expand access";
export const CARD_TWO_DESCRIPTION = "Making the ebooks facility accessible nationally, including provision of courses from universities.";
export const CARD_THREE_TITLE = "Enable innovation";
export const CARD_THREE_DESCRIPTION = "Enabling Abrehot Library to be at the heart of Ethiopia's infrastructure for research and development.";
export const CARD_FOUR_TITLE = "Catalyse Abrehot";
export const CARD_FOUR_DESCRIPTION = "Catalyse Abrehot Library to be a National Technological Innovation Hub by providing financial and material support aligned with the nation’s needs.";
export const TESTIMONIALS_HEADER = "Join our global community of supporters.";
//#endregion


//#region HomePage
export const BIO_BETHELHEM = { name: "Bethlehem Solomon", bio: "Bethlehem Solomon received her MPH in Epidemiology and Global Health from Boston University and is currently a PhD candidate and Teaching Fellow in Public Health at Imperial College London. Bethlehem founded the Abren Le Abrehot initiative in January 2022." };
export const BIO_DINA = { name: "Dina Gelaye", bio: "Dina received her MS in Biomedical Sciences from Liberty University and currently works as a Project Coordinator in cancer screening research. Dina is passionate about global health/medicine and healthcare accessibility. She joined the Abren Le Abrehot initiative with hopes to reduce barriers related to access to books in Ethiopia." };
export const BIO_WES = { name: "Wes Maru", bio: "Dr. Wes Maru holds a PhD in Aerospace Engineering from Cranfield University and an EMBA from University of Cambridge. He is currently the R&D Director of OGM Energy, UK. Wes is passionate about education, innovation and knowledge transfer to world youth in general, and to those in Ethiopia in particular." };
export const BIO_ESAYAS = { name: "Esayas Shume", bio: "Dr. Shume received his PhD in Space Sciences from Cornell University. He currently works as a Program Scientist at NASA Heliophysics Division. His interests include (1) outreach and making science and technology accessible; (2) technology development." };
export const BIO_SAMRAWIT = { name: "Samrawit Gebremichael", bio: "Samrawit (Samri) Gebremichael is a Research Project Coordinator at A.T. Still University, an Osteopathic Medical School. She received her MS in Biomedical Sciences from Liberty University. She is a passionate advocate of quality education for disadvantaged children." };
export const BIO_KIRUBEL = { name: "Kirubel Tolosa", bio: "Kirubel received his MSC in Information Systems at the University of Maryland, Baltimore County, and is currently working as a software engineer. He hopes to contribute to the empowerment of young people in Ethiopia through education." };
export const BIO_MICHAEL = { name: "Michael Abebe", bio: "Michael received his BSE in Computer Science from Princeton University, and currently works as a software engineer. He is originally from Addis Ababa, Ethiopia and hopes to work towards improving the education and opportunities available to students in the country." };
export const BIO_BEMNET = { name: "Bemnet Demere", bio: "Bemnet received his Bachelor’s Degree in Computer Science at Trinity College, Hartford CT and currently is working at Google as a Software Engineer while he is working part-time towards his master’s degree in Computer Science. He joined abrohot, understanding the importance of knowledge, eager to contribute to the initiative of facilitating the increase of opportunity of education in Ethiopia." };
//#endregion

//#region About
export const OUR_WORK = "The Abren Le Abrehot initiative, which began in January 2022, aimed to collect books to donate to the then-newly inaugurated Abrehot (“Enlightenment”) Library in Addis Ababa, Ethiopia. The purpose of the initiative is to not only donate books but create an avenue for exploration and understanding; provide exposure to new people, places and ideas; equip people with a passionate pursuit for even greater dreams; and develop vital skills like writing and critical thinking. The Abrehot Global Support Group is expanding the initiative to improve Science, Technology, Engineering and Mathematics (STEM) education by supporting the acquisition of eBooks, particularly with an emphasis on high school (11-12th grade) and early university (1st and 2nd year) students.";
//#endregion

//#region Story
export const STORY_BACKGROUND_1 = "Abren Le Abrehot (አብረን ለአብሬሆት) was started in January 2022 by Bethlehem Solomon, with the aim to donate books to the then-newly inaugurated Abrehot (“Enlightenment”) Library in Addis Ababa, Ethiopia.";
export const STORY_BACKGROUND_2 = "The purpose of the initiative, however, is more than simply donating books. Abren Le Abrehot is about creating an avenue for exploration and understanding;  providing exposure to new people, places and ideas;  equipping people with a passionate pursuit for even greater dreams; and developing vital skills like writing and critical thinking.";
export const STORY_BACKGROUND_3 = "In April 2022, Bethlehem single-handedly donated over 5,200 books to Abrehot Library. The handover ceremony at Abrehot was graced by Ethiopian government representative, garnering attention from multiple national media outlets. Most importantly, however, Bethlehem’s initiative spurred a movement throughout the global Ethiopian diaspora and friends of Ethiopia to give back to Ethiopia through book donations.";

export const STORY_CURRENTwORK_1 = "The Abrehot Global Support Group is expanding the Abren Le Abrehot initiative to improve access to Science, Technology, Engineering and Mathematics (STEM) education by supporting the acquisition of eBooks, particularly with an emphasis on high school (11-12th grade) and early university (1st and 2nd year) students.  ";
export const STORY_CURRENTwORK_2 = "STEM textbooks are particularly scarce and an immediate need in Ethiopia; and are vital in improving lives and the development of Ethiopia by reducing poverty, feuling innovation, and creating sustainable communities.";
export const STORY_CURRENTwORK_3 = "The major goal of the Abren Le Abrehot initiative is to provide access to STEM eBooks thereby mitigating the dire shortage of STEM books. The Abrehot global support group believes that the access to eBooks will maximize the country's youth population engagement and participation in innovation and technology development.";
export const STORY_CURRENTwORK_4 = "We focus on digital textbooks to (1) reduce the monetary and labor costs associated with print textbooks, and (2) increase the accessibility of the textbooks to Abrehot’s members outside of the physical library so that there is more flexibility for students. ";
export const STORY_CURRENTwORK_5 = "We believe that this expansion of Abren Le Abrehot will be pivotal in increasing the impact of the initiative in a way that will have even wider and longer-lasting significance on the upcoming generation, its leaders, and the country. ";
//#endregion

//region Testimonials
export const TESTIMONIALS_1 = "On April 7, 2022, a group of us from the Washington DC area watched the inauguration of the grand Abrehot Library, which we were inspired by both Betty (last name)’s generous book donation and the speech of the Prime minister of Ethiopia, Dr. Abiy Ahmed. The Prime Minister said “ብርሃን ፡ የ ፡ ማየት፣ የ ማመን፣ እና ፡ የ ፡ መድፈር ፡ ምንጭ ፡ ነው”. The Prime minister was comparing education to light because just like light enables seeing, education enables us to believe, envision, and dream big. Inspired by his speech, me and two other friends began collecting books from our families, friends, local churches, and people we knew. We collected all sort of books staring from April 2022 till August 2022. We collected around 250 books and during the summer of August 2022 we were able to go in person to donate the books. This project was really inspirational for all of us involved and taught us that we can use the somewhat small resources around us to make a big impact. Being able to see the beautiful architecture of Abrehot and the educational opportunity it will provide for the generation instilled a lot of hope in us for the development of our country.";
export const TESTIMONIALS_2 = "For the past several years, my mother and I have led donation drives throughout our community to help provide books and educational materials to Ethiopian and other families who are newcomer to the US living in Clarkston, GA, the most diverse square mile in the country and home to a large Ethiopian population. When we heard about Abrehot Library's grand opening, my mom began looking for ways to expand our ongoing donation work to support Abrehot's mission of enlightening Ethiopia's young and bright minds.  In the process, we learned about Abren Le Abrehot and reached out to Bethlehem, who connected us with the Abrehot team. As a result, we were able to quickly organize a book-transfer, and my mom soon sent over two hundred children’s books to Ethiopia. Thanks to Abren Le Abrehot, we were able to get the information we needed to take our local project across the ocean to Addis Ababa. ";
//#endregion