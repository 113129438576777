import React from 'react'
import Initiative from './Initiative';
import Cards from './Cards';
import * as messages from '../../constants/constants';
import Testimonials from "./Testimonials/Testimonials";
import {Grid, Typography, useMediaQuery} from "@mui/material";
import { grey } from "@mui/material/colors";
import slideshow from "./Testimonials/Slideshow";
import {createTheme} from "@mui/material/styles";

const theme = createTheme({});

function Landing() {
    const heroStyle = {
        marginTop: "40px",
        marginBottom: "20px",
        paddingBottom: "25px",
    }

    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const gridWidth = matches ? 12 : 9;

    return (
        <>
            <Grid container direction="column" style={heroStyle} spacing={3}>
                <Grid item alignSelf="center">
                    <Typography fontWeight={800} fontSize="2.75rem" lineHeight="1.7rem" color={grey[600]}>{messages.SLOGAN_MAIN_TITLE}</Typography>
                </Grid>
                <Grid container item justifyContent="center" alignSelf="center" textAlign="center">
                    <Grid item xs={gridWidth} alignSelf="center">
                        <Typography fontWeight={700} fontSize="1.4rem" lineHeight="1.5rem" color={grey[600]}>{messages.SLOGAN_MAIN}</Typography>
                    </Grid>
                </Grid>
                <Grid container item alignSelf="center">
                    {slideshow()}
                </Grid>
                <Grid item>
                    <h1 className='w-full text-4xl font-semibold mt-4 justify-center text-center text-abrehotYellow'>Our Mission</h1>
                </Grid>
                <Grid item>
                    <Cards />
                </Grid>
                <br />
                <Grid item>
                    <Testimonials />
                </Grid>
            </Grid>
        </>
    )
}

export default Landing


